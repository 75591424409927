/* dsadasdasdasdasdas
dsadasdasdasdasdas
dsadasdasdasdasdas
dsadasdasdasdasdas
v
dsadasdasdasdasdas
v
v
v

*/


.metas::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}

.metas::-webkit-scrollbar-track {
    background: #e0e0e0;
}

.metas::-webkit-scrollbar-thumb {
    background: #888;
}

.metas::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.metas .avatar>img {
    width: auto !important;
}

.metas .boxShadow>div>div>svg>g>g:nth-child(2) path {
    animation: pulse 1.5s ease-out infinite;
}

.metas .boxShadow>div>div>svg>g>g:nth-child(4)>g:nth-child(3)>text {
    font-size: 20px !important;
    font-weight: bold;
}

.metas .noShadow>div>div>svg>g>g:nth-child(4)>g:nth-child(3)>text {
    font-size: 20px !important;
    font-weight: bold;
}

.metas .boxShadowGreen>div>div>svg {
    box-shadow: 0px 10px 15px 0px green !important;
    transition: transform ease 0.3s, box-shadow ease 0.3s !important;
}

@keyframes pulse {
    from {
        opacity: 0;
        /* opacity: 0; */
    }

    to {
        opacity: 1;
        /* opacity: 1; */
    }
}

.metas.MuiAvatar-img {
    object-fit: contain !important;
}